<!-- 注册参会 -->
<template>
  <div class="registerMeetingPageContainer">
    <el-row>
      <el-col :span="24" :lg="24">
        <div class="pageBox">
          <div class="header pc">
            <el-image
              style="width: 100%"
              :src="require('../../assets/images/registerMeeting/banner.png')"
              :fit="'fill'"
            >
            </el-image>
            <div class="title-box">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("participation") }}
              </div>
              <div class="subtitle">
                <div
                  class="register"
                  :class="{ weibei: lang }"
                  @click="$router.push({ path: '/registerMeeting' })"
                >
                  {{ $t("regist") }}
                </div>
                <div
                  class="traffic"
                  :class="{ weibei: lang }"
                  @click="
                    $router.push({ path: '/transportationAndAccommodation' })
                  "
                >
                  {{ $t("logistics") }}
                </div>
              </div>
            </div>
          </div>
          <div class="header h5">
            <img
              src="@/assets/images/registerMeeting/banner1.png"
              alt=""
              style="width: 100%; height: 100%; object-fit: cover"
            />
            <div class="title-box">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("participation") }}
              </div>
              <div class="subtitle">
                <div
                  class="register"
                  :class="{ weibei: lang }"
                  @click="$router.push({ path: '/registerMeeting' })"
                >
                  {{ $t("regist") }}
                </div>
                <div
                  class="traffic"
                  :class="{ weibei: lang }"
                  @click="
                    $router.push({ path: '/transportationAndAccommodation' })
                  "
                >
                  {{ $t("logistics") }}
                </div>
              </div>
            </div>
          </div>
          <div class="lcontainer container">
            <!-- <div class="title m-b-28" :class="{ weibei: lang }">
              {{ $t("registration") }}
            </div> -->

            <div class="subtitle-box m-b-60 pc">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "一、" : "1. " }}{{ $t("registrationOverview") }}
              </div>
              <div class="m-b-20 p-indent">
                <p class="subbtitle">{{ $t("Inpersonparticipation") }}</p>
                <p class="m-t-b-15" v-html="$t('Inp1')"></p>
                <!-- <p>{{ $t("Inp2") }}</p> -->
              </div>
              <!-- <div class="button">{{ $t('Inpersonparticipation') }}</div> -->
              <div class="p-indent">
                <p class="subbtitle">{{ $t("virtualAttendance") }}</p>
                <div class="m-t-b-15">
                  {{ $t("vip1")
                  }}<span class="g-color">{{ $t("viptime") }}</span
                  >{{ lang ? "。" : "." }}
                  <span v-if="lang"
                    >{{ $t("vip11")
                    }}<span
                      class="a-color"
                      @click="$router.push({ path: '/commonProblem' })"
                      >{{ $t("here") }}</span
                    >
                    {{ lang ? "。" : "." }}</span
                  >
                  <p v-if="!lang" style="margin-top: 20px">
                    For frequently asked questions on virtual attendance, please
                    see
                    <span
                      class="a-color"
                      @click="$router.push({ path: '/commonProblem' })"
                      >here</span
                    >.
                  </p>
                </div>
              </div>
              <div class="button" :style="lang ? '' : 'width: 345px;'">
                <a
                  href="https://barc2022.chinafuturelink.com/#/meeting/activity/detail?activity_id=62902ce72e3cf711044637d3&source_id=62902ce72e3cf711044637d2"
                  target="_blank"
                  class="btnStyle"
                  style="
                    text-decoration: none;
                    font-size: 28px;
                    color: #fff;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    justify-content: center;
                  "
                  >{{ $t("virtualAttendance") }}</a
                >
              </div>
              <div class="p-indent">{{ $t("vip2") }}</div>
            </div>

            <div class="subtitle-box m-b-60 h5">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "一、" : "1. " }}{{ $t("registrationOverview") }}
              </div>
              <div class="m-b-20">
                <p class="subbtitle">{{ $t("Inpersonparticipation") }}</p>
                <p class="m-t-b-15" v-html="$t('Inp1')"></p>
                <!-- <p>{{ $t("Inp2") }}</p> -->
              </div>
              <!-- <div class="button">{{ $t('Inpersonparticipation') }}</div> -->
              <div>
                <p class="subbtitle">{{ $t("virtualAttendance") }}</p>
                <div class="m-t-b-15">
                  {{ $t("vip1")
                  }}<span class="g-color">{{ $t("viptime") }}</span
                  >{{ lang ? "。" : "." }}
                  {{
                    lang
                      ? ""
                      : "Please be noted that all registrationdeadlines are Beijing time (GMT+8). "
                  }}<span v-if="lang"
                    >{{ $t("vip11")
                    }}<span
                      class="a-color"
                      @click="$router.push({ path: '/commonProblem' })"
                      >{{ $t("here") }}</span
                    >
                    {{ lang ? "。" : "." }}</span
                  >
                  <p v-if="!lang" style="margin-top: 20px">
                    For frequently asked questions on virtual attendance, please
                    see
                    <span
                      class="a-color"
                      @click="$router.push({ path: '/commonProblem' })"
                      >here</span
                    >.
                  </p>
                </div>
              </div>
              <div class="button">
                <a
                  href="https://barc2022.chinafuturelink.com/#/meeting/activity/detail?activity_id=62902ce72e3cf711044637d3&source_id=62902ce72e3cf711044637d2"
                  target="_blank"
                  style="
                    text-decoration: none;
                    color: #fff;
                    width: 100%;
                    height: 100%;
                    display: block;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    justify-content: center;
                  "
                  >{{ $t("virtualAttendance") }}</a
                >
              </div>
              <div style="font-size: 14px">{{ $t("vip2") }}</div>
            </div>

            <!-- 注册费 -->
            <div class="subtitle-box pc" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                2. {{ $t("registrationFee") }}
              </div>
              <div class="p-indent pc">
                <div class="flex a-c m-b-24">
                  <span class="dot"></span> {{ $t("rep1") }}
                </div>
                <div class="flex a-c m-b-24">
                  <span class="dot"></span>{{ $t("rep2") }}
                </div>
                <div class="flex a-c m-b-24">
                  <div>
                    <span
                      class="dot"
                      style="display: inline-block; margin-bottom: 3px"
                    ></span
                    >{{ $t("rep3") }}
                    <span class="g-color">barc2022_info@inbar.int</span>；
                  </div>
                </div>
                <div class="flex a-c m-b-60">
                  <span class="dot"></span> {{ $t("rep4") }}。
                </div>
              </div>
              <div class="p-indent h5">
                <div class="flex m-b-24">
                  <span class="dot m-t-8"></span> {{ $t("rep1") }}
                </div>
                <div class="flex m-b-24">
                  <span class="dot m-t-8"></span> {{ $t("rep2") }}
                </div>
                <div class="flex a-c m-b-24">
                  <div>
                    <span
                      class="dot"
                      style="display: inline-block; margin-bottom: 3px"
                    ></span
                    >{{ $t("rep3") }}
                    <span class="g-color">barc2022_info@inbar.int</span>；
                  </div>
                </div>
                <div class="flex m-b-60">
                  <span class="dot m-t-8"></span> {{ $t("rep4") }}。
                </div>
              </div>
            </div>
            <div class="subtitle-box h5" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                2. {{ $t("registrationFee") }}
              </div>
              <div class="pc">
                <div class="flex a-c m-b-24">
                  <span class="dot"></span> {{ $t("rep1") }}
                </div>
                <div class="flex a-c m-b-24">
                  <span class="dot"></span>{{ $t("rep2") }}
                </div>
                <div class="flex a-c m-b-24">
                  <div>
                    <span
                      class="dot"
                      style="display: inline-block; margin-bottom: 3px"
                    ></span
                    >{{ $t("rep3") }}
                    <span class="g-color">barc2022_info@inbar.int</span>；
                  </div>
                </div>
                <div class="flex a-c m-b-60">
                  <span class="dot"></span> {{ $t("rep4") }}。
                </div>
              </div>
              <div class="h5">
                <div class="flex m-b-24">
                  <span class="dot m-t-8"></span> {{ $t("rep1") }}
                </div>
                <div class="flex m-b-24">
                  <span class="dot m-t-8"></span> {{ $t("rep2") }}
                </div>
                <div class="flex a-c m-b-24">
                  <div>
                    <span
                      class="dot"
                      style="display: inline-block; margin-bottom: 3px"
                    ></span
                    >{{ $t("rep3") }}
                    <span class="g-color">barc2022_info@inbar.int</span>；
                  </div>
                </div>
                <div class="flex m-b-60">
                  <span class="dot m-t-8"></span> {{ $t("rep4") }}。
                </div>
              </div>
            </div>

            <!-- 参会权益 -->
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "二、" : "2. " }}{{ $t("registrationBenefits") }}
              </div>
              <div class="table-box pc">
                <!-- <el-image style="width: 100%;" :src="tabImg1" :fit="'fill'">
                                </el-image> -->
                <table border="1" cellspacing="0">
                  <tbody>
                    <tr
                      style="
                        background: #8dc32b;
                        color: #fff;
                        height: 60px;
                        font-weight: 700;
                      "
                    >
                      <td valign="center" width="200" height="60">
                        <p align="center">
                          <span style="font-size: 16px !important">{{
                            $t("registerTable.a")
                          }}</span>
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span style="font-size: 16px !important">{{
                            $t("registerTable.b")
                          }}</span>
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span style="font-size: 16px !important">{{
                            $t("registerTable.c")
                          }}</span>
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span style="font-size: 16px !important">{{
                            $t("registerTable.d")
                          }}</span>
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span
                            style="font-size: 16px !important"
                            v-html="$t('registerTable.e')"
                          ></span>
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span style="font-size: 16px !important">{{
                            $t("registerTable.f")
                          }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" width="200" height="60">
                        <p align="center">
                          <span style="font-size: 24px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span style="font-size: 24px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span style="font-size: 24px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span style="font-size: 24px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span style="font-size: 24px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center" width="200">
                        <p align="center">
                          <span style="font-size: 24px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="h5"
                :style="lang ? '' : 'transform: scale(0.9);margin-left: -30px;'"
              >
                <!-- <el-image style="width: 100%;" :src="tabImg1" :fit="'fill'">
                                </el-image> -->
                <table border="1" cellspacing="0">
                  <tbody>
                    <tr
                      style="background: #8dc32b; color: #fff; font-weight: 700"
                    >
                      <td valign="center" height="40">
                        <p align="center">
                          <span style="font-size: 14px">{{
                            $t("registerTable.a")
                          }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span style="font-size: 14px">{{
                            $t("registerTable.b")
                          }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span style="font-size: 14px">{{
                            $t("registerTable.c")
                          }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span style="font-size: 14px">{{
                            $t("registerTable.d")
                          }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span
                            style="font-size: 14px"
                            v-html="$t('registerTable.e')"
                          ></span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span style="font-size: 14px">{{
                            $t("registerTable.f")
                          }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" height="40">
                        <p align="center">
                          <span style="font-size: 18px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span style="font-size: 18px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span style="font-size: 18px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span style="font-size: 18px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span style="font-size: 18px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span style="font-size: 18px; font-family: cursive">
                            {{ $t("registerTable.v") }}</span
                          >
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="p-indent m-t-24 pc" v-if="0">
                <div class="flex a-c m-b-24 pc">
                  <span class="dot"></span> {{ $t("regp1") }}
                </div>
                <div class="flex m-b-24 h5">
                  <span class="dot m-t-8"></span> {{ $t("regp1") }}
                </div>
                <div class="flex m-b-24 f-b">
                  <span class="dot m-t-8"></span> {{ $t("regp2") }}
                </div>
              </div>
              <div class="m-t-24 h5" v-if="0">
                <div class="flex a-c m-b-24 pc">
                  <span class="dot"></span> {{ $t("regp1") }}
                </div>
                <div class="flex m-b-24 h5">
                  <span class="dot m-t-8"></span> {{ $t("regp1") }}
                </div>
                <div class="flex m-b-24 f-b">
                  <span class="dot m-t-8"></span> {{ $t("regp2") }}
                </div>
              </div>
            </div>
            <!-- 4.注册和取消政策 -->
            <div class="subtitle-box m-t-60 pc" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "三、" : "3. "
                }}{{ $t("registrationCancellationPolicy") }}
              </div>
              <div class="p-indent m-t-24">
                <!-- <p>{{ $t('registp1') }}</p> -->
                <p class="subbtitle m-t-24 m-b-16">a.{{ $t("registp2") }}</p>
                <p class="m-b-16" v-html="$t('registp3')"></p>
                <p>{{ $t("registp4") }}</p>
                <div class="tab-box" style="width: 740px">
                  <!-- <el-image style="width: 100%;" :src="tabImg2" :fit="'fill'">
                                    </el-image> -->
                  <table border="1" cellspacing="0" width="100%">
                    <tbody>
                      <tr style="">
                        <td valign="center" width="50%" height="50">
                          <p align="center">
                            <span class="f-b">{{
                              $t("registerTable2.a")
                            }}</span>
                          </p>
                        </td>
                        <td valign="center" width="50%">
                          <p align="center">
                            <span class="f-b">{{
                              $t("registerTable2.aa")
                            }}</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td valign="center" width="50%" height="50">
                          <p align="center">
                            <span class="f-b">
                              {{ $t("registerTable2.b") }}</span
                            >
                          </p>
                        </td>
                        <td valign="center" width="50%">
                          <p align="center">
                            <span class="f-b">
                              {{ $t("registerTable2.bb") }}</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="subbtitle m-t-24 m-b-16">b. {{ $t("registp5") }}</p>
                <p v-html="$t('registp6')"></p>
              </div>
            </div>

            <div class="subtitle-box m-t-38 h5" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "三、" : "3. " }}
                {{ $t("registrationCancellationPolicy") }}
              </div>
              <div class="m-t-24">
                <!-- <p>{{ $t('registp1') }}</p> -->
                <p class="subbtitle m-t-24 m-b-16">a.{{ $t("registp2") }}</p>
                <p class="m-b-16" v-html="$t('registp3')"></p>
                <p>{{ $t("registp4") }}</p>
                <div class="tab-box h5">
                  <table border="1" cellspacing="0" width="100%">
                    <tbody>
                      <tr style="">
                        <td valign="center" width="50%" height="50">
                          <p align="center">
                            <span class="f-b">{{
                              $t("registerTable2.a")
                            }}</span>
                          </p>
                        </td>
                        <td valign="center" width="50%">
                          <p align="center">
                            <span class="f-b">{{
                              $t("registerTable2.aa")
                            }}</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td valign="center" width="50%" height="50">
                          <p align="center">
                            <span class="f-b">
                              {{ $t("registerTable2.b") }}</span
                            >
                          </p>
                        </td>
                        <td valign="center" width="50%">
                          <p align="center">
                            <span class="f-b">
                              {{ $t("registerTable2.bb") }}</span
                            >
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="subbtitle m-t-24 m-b-16">b. {{ $t("registp5") }}</p>
                <p v-html="$t('registp6')"></p>
              </div>
            </div>

            <!-- 5. 隐私政策 -->
            <div class="subtitle-box m-t-60 pc">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "三、" : "3. " }}{{ $t("privacyPolicy") }}
              </div>
              <div class="p-indent m-t-24">
                <p>{{ $t("prip1") }}</p>
              </div>
            </div>

            <div class="subtitle-box m-t-38 h5">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "三、" : "3. " }}{{ $t("privacyPolicy") }}
              </div>
              <div class="m-t-24">
                <p>{{ $t("prip1") }}</p>
              </div>
            </div>

            <!-- 6. 数据保护 -->
            <div class="subtitle-box m-t-60 pc">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "四、" : "4. " }}{{ $t("dataProtection") }}
              </div>
              <div class="p-indent m-t-24">
                <p v-html="$t('datap1')"></p>
              </div>
            </div>

            <div class="subtitle-box m-t-38 h5">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "四、" : "4. " }}{{ $t("dataProtection") }}
              </div>
              <div class="m-t-24">
                <p v-html="$t('datap1')"></p>
              </div>
            </div>

            <!-- 7. 保险 -->
            <div class="subtitle-box m-t-60 pc">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "五、" : "5. " }}{{ $t("insurance") }}
              </div>
              <div class="p-indent m-t-24">
                <p>{{ $t("insp1") }}</p>
              </div>
            </div>

            <div class="subtitle-box m-t-38 h5">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "五、" : "5. " }}{{ $t("insurance") }}
              </div>
              <div class="m-t-24">
                <p>{{ $t("insp1") }}</p>
              </div>
            </div>

            <!-- 8. 责任范围 -->
            <div class="subtitle-box m-t-60 pc">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "六、" : "6. " }}{{ $t("limitationofLiability") }}
              </div>
              <div class="p-indent m-t-24">
                <div class="flex m-b-24">
                  {{ $t("limip1") }}
                </div>
                <div class="flex m-b-24">
                  {{ $t("limip2") }}
                </div>
                <!-- <div class="flex m-b-24">
                                    {{ $t('limip3') }}</div> -->
              </div>
            </div>

            <div class="subtitle-box m-t-38 h5">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "六、" : "6. " }}{{ $t("limitationofLiability") }}
              </div>
              <div class="m-t-24">
                <div class="flex m-b-24">
                  <span class="m-t-8"></span> {{ $t("limip1") }}
                </div>
                <div class="flex m-b-24">
                  <span class="m-t-8"></span>
                  {{ $t("limip2") }}
                </div>
                <!-- <div class="flex m-b-24"><span class=" m-t-8"></span>
                                    {{ $t('limip3') }}</div> -->
              </div>
            </div>

            <!-- 9. 媒体注册 -->
            <div class="subtitle-box m-t-60 pc">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "七、" : "7. " }}{{ $t("mediaRegistration") }}
              </div>
              <div class="p-indent m-t-24">
                <p>{{ $t("mediap1") }}</p>
                <p class="xtitle m-b-16 m-t-24" v-if="0">{{ $t("mediap2") }}</p>
                <p></p>
                <p>
                  <span v-html="$t('mediap3')"></span
                  ><span v-html="$t('mediap33')"></span
                  ><span v-html="$t('mediap333')"></span>
                </p>
                <!-- <p class="subbtitle m-t-24">{{ $t("mediap4") }}</p>
                <div class="flex m-t-14 m-b-14">
                  <span class="dot m-t-8"></span>{{ $t("mediap5") }}
                </div> -->
                <!-- <div class="flex m-b-14"><span class="dot m-t-8"></span>{{ $t('mediap6') }} </div> -->
                <!-- <div class="flex">
                  <span class="dot m-t-8"></span>{{ $t("mediap7") }}
                </div> -->
              </div>
            </div>

            <div class="subtitle-box m-t-38 h5">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "七、" : "7. " }}{{ $t("mediaRegistration") }}
              </div>
              <div class="m-t-24">
                <p>{{ $t("mediap1") }}</p>
                <p class="xtitle m-b-16 m-t-24" v-if="0">{{ $t("mediap2") }}</p>
                <!-- <p v-html="$t('mediap3')"></p> -->
                <p>
                  <span v-html="$t('mediap3')"></span
                  ><span v-html="$t('mediap33')"></span
                  ><span v-html="$t('mediap333')"></span>
                </p>
                <!-- <p class="subbtitle m-t-24">{{ $t("mediap4") }}</p>
                <div class="flex m-t-14 m-b-14">
                  <span class="dot m-t-8"></span>{{ $t("mediap5") }}
                </div> -->
                <!-- <div class="flex m-b-14"><span class="dot m-t-8"></span>{{ $t('mediap6') }} </div> -->
                <!-- <div class="flex">
                  <span class="dot m-t-8"></span>{{ $t("mediap7") }}
                </div> -->
              </div>
            </div>

            <!-- 10. 其他问题 -->
            <div class="subtitle-box m-t-60 pc">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "八、" : "8. " }}{{ $t("inquiries") }}
              </div>
              <div class="p-indent m-t-24">
                <p v-html="$t('inqup1')"></p>
              </div>
            </div>
            <div class="subtitle-box m-t-38 h5">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ lang ? "八、" : "8. " }}{{ $t("inquiries") }}
              </div>
              <div class="m-t-24">
                <p v-html="$t('inqup1')"></p>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabImg1: null,
      tabImg2: null,
    };
  },
  created() {
    this.innit();
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {
    openUrl() {
      if (window.localStorage.getItem("locale") == "zh-CN") {
        window.open(
          "https://www.barc2022.inbar.int/barcfile/BARC 2022媒体注册表_20220531_中.docx"
        );
      } else {
        window.open(
          "https://www.barc2022.inbar.int/barcfile/BARC 2022媒体注册表_20220531_英.docx"
        );
      }
    },
    innit() {
      if (window.localStorage.getItem("locale") == "zh-CN") {
        this.tabImg1 = require("../../assets/images/registerMeeting/table.png");
        this.tabImg2 = require("../../assets/images/registerMeeting/tab.png");
      } else {
        this.tabImg1 = require("../../assets/images/registerMeeting/table-en.png");
        this.tabImg2 = require("../../assets/images/registerMeeting/tab-en.png");
      }
    },
    toCommonProblem() {
      console.log("toCommonProblem");
    },
  },
};
</script>
<style lang="stylus">
@media screen and (min-width: 728px)
  .registerMeetingPageContainer
    .pageBox
      .header
        .el-image
          display: block;

</style>
<style lang="stylus" scoped>
@media screen and (min-width: 728px) {
  .registerMeetingPageContainer {
    background: #FAFAFA;

    table, td, th {
      border: 1px solid #eaeaea;
    }

    span {
      word-wrap: break-word;
    }

    p, div {
      line-height: 1.5;
    }

    .pageBox {
      .h5 {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;

        .title-box {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          transform: translate(-50%, -50%);

          .title {
            padding: 0 20px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            background: rgba(141, 198, 63, 0.9);
            font-weight: 700;
            font-size: 42px;
            margin: 0 auto 30px auto;
            width: fit-content;
          }

          .subtitle {
            display: flex;
            font-size: 20px;
            justify-content: center;
          }

          .register {
            width: 130px;
            height: 54px;
            line-height: 54px;
            top: 379px;
            background: #405E3C;
            margin-right: 24px;
            cursor: pointer;
          }

          .traffic {
            width: 130px;
            height: 54px;
            line-height: 54px;
            top: 379px;
            background: #8DC63F;
            cursor: pointer;
          }
        }
      }

      .container {
        width: 100%;
        max-width: 1520px;
        margin: auto;
        padding: 69px 60px 56px 60px;
        box-sizing: border-box;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #405E3C;
          margin-bottom: 14px;
        }

        .subtitle-box {
          text-align: left;

          .subtitle {
            font-size: 28px;
            color: #405E3C;
            margin-bottom: 28px;
            font-weight: 700;
          }
        }

        .button {
          margin: 40px auto;
          width: 218px;
          height: 59px;
          background: #8DC63F;
          font-size: 20px;
          color: #fff;
          cursor: pointer;
        }

        .table-box {
          width: 1058px;
          margin: 0 auto;
          margin-top: 20px;
        }

        .tab-box {
          width: 740px;
          margin: 0 auto;
          margin-top: 30px;
        }
      }
    }
  }

  td {
    span {
      font-size: 20px !important;
    }
  }
}

.btnStyle:hover {
  background: #405e3c;
}

@media screen and (max-width: 728px) {
  .registerMeetingPageContainer {
    p {
      line-height: 1.5;
      width: 100%;
      overflow: hidden;
    }

    .pageBox {
      .pc {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;
        height: 190px;
        width: 100%;

        .title-box {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          transform: translate(-50%, -50%);

          .title {
            width: 164px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: rgba(141, 198, 63, 0.9);
            font-weight: 700;
            font-size: 22px;
            margin: 0 0 30px 45px;
          }

          .subtitle {
            display: flex;
            font-size: 20px;
          }

          .register {
            width: 100px;
            height: 44px;
            line-height: 44px;
            top: 379px;
            background: #405E3C;
            margin-right: 24px;
          }

          .traffic {
            width: 100px;
            height: 44px;
            line-height: 44px;
            top: 379px;
            background: #8DC63F;
          }
        }
      }

      .container {
        padding: 20px;
        margin-top: 20px;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #405E3C;
        }

        .subtitle-box {
          text-align: left;

          .subtitle {
            font-size: 20px;
            color: #405E3C;
            margin-bottom: 15px;
            font-weight: 700;
          }
        }

        .button {
          margin: 40px auto;
          width: 288px;
          height: 60px;
          line-height: 60px;
          background: #8DC63F;
          font-size: 20px;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }

        .table-box {
          width: 1058px;
          margin: 0 auto;
          margin-top: 20px;
        }

        .tab-box {
          margin: 0 auto;
          margin-top: 30px;
        }
      }
    }
  }

  .subbtitle {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  .m-b-24 {
    font-size: 14px;
  }

  .m-t-b-15 {
    font-size: 14px;
  }

  .p-indent {
    font-size: 14px;
  }

  .flex {
    font-size: 14px;
  }
}
</style>